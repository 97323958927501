/*--------------------------------------------------------------
# Sidebar 
--------------------------------------------------------------*/


/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 14px;
  bottom: 0;
  left: 0;
  z-index: 0;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  border-right: 1px solid #eee;
}

/* Sidebar navigation */
.sidebar {
  padding-left: 0;
  padding-right: 0;
}

.sidebar .nav {
  margin-bottom: 20px;
}

.sidebar .nav-item {
  width: 100%;
}

.sidebar .nav-item + .nav-item {
  margin-left: 0;
}

.sidebar .nav-link {
  border-radius: 0;
}

