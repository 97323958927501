/*--------------------------------------------------------------
# Variables
--------------------------------------------------------------*/
$enable-flex:               true;

// Colors
$brand-primary:             #4F90B8;     


// Navbar

$navbar-toggler-padding-x:           .25rem !default;
$navbar-toggler-padding-y:           .25rem !default;
$navbar-toggler-border-radius:       .25rem !default;


$navbar-inverse-color:                 rgba(255,255,255,.5) !default;
$navbar-inverse-hover-color:           rgba(255,255,255,.75) !default;
$navbar-inverse-active-color:          rgba(255,255,255,1) !default;
$navbar-inverse-disabled-color:        rgba(255,255,255,.25) !default;

