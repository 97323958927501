.footer {
  
  
  text-align: center;
  color: #fff;

  p {
    margin: 0;
    font-size: .9rem;
    text-align: center;
  }
}
