/*--------------------------------------------------------------
# Global
--------------------------------------------------------------*/
html, body {
  overflow-x: hidden;
}

html {
  font-size: 14px;
}

main { margin-top: 48px; margin-bottom: 3px; }
.main-projects { margin-top: 121px; }
.projects { margin-top: 48px; }
.sidebar { margin-top: 104px;}

@include media-breakpoint-up(sm) {
  html {
    font-size: 14px;
  }
  main { margin-top: 48px; margin-bottom: 3px; }
  .main-projects { margin-top: 73px; }
  .projects { margin-top: 48px; }
  .sidebar { margin-top: 56px;}
}

@include media-breakpoint-up(md) {
  html {
    font-size: 16px;
  }
  main { margin-top: 49px; margin-bottom: 3px; }
  .main-projects { margin-top: 74px; }
  .projects { margin-top: 49px; }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 20px;
  }
  main { margin-top: 50px; margin-bottom: 3px; }
  .main-projects { margin-top: 77px; }
  .projects { margin-top: 50px; }
  .navbar-brand { img { margin-top: -8px; }}

}
