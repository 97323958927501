/*--------------------------------------------------------------
# Masthead
--------------------------------------------------------------*/
.masthead {
  width: 100vw;
  height: 100vh;

  .masthead-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba($brand-primary, .7);
    flex-direction: column;

    .masthead-text {
      margin: auto;
      color: #fff;
      opacity: 1;
      text-align: center;

      h1 {
        font-size: 4rem;
      }
    }
  }
}
