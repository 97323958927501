.glava {
	padding: 0px 5px 1px 10px;
}

.navbar-toggler {
	background-color: #555;
	margin-top: 10px;
	font-size: 14px;
}
	
.navbar-brand {
	img {
	height: 40px;
  
  }
}

.projects {
  padding: 3px 0px 3px 0px;
  line-height: .5em;
  font-size: 84%;
  text-align: center;
  text-transform: uppercase;
  background-color: #000;
  z-index: 1;
  
.nav-item { padding: 3px 4px 3px 0px; }
.nav-link { padding: 2px 4px 2px 0px; }
.active.nav-link { color: #f00; }

}

